import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { LocalStorageManagementService } from '../services/base/local-storage-management.service';

@Injectable()
export class CustomerInterceptor implements HttpInterceptor {

    constructor(
        private localStorageService: LocalStorageManagementService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let apiRequest: HttpRequest<any>;
        const customer = this.localStorageService.getSelectedCustomer();
        const isInterceptableUrl = request.url.includes('companies')
            || request.url.includes('expense_reports')
            || request.url.includes('users');

        if (customer && isInterceptableUrl) {
            if (request.method === 'GET') {
                apiRequest = request.clone({
                    params: request.params.set('customer_id', customer.id.toString())
                });
            } else {
                apiRequest = request.clone({
                    body: {
                        ...request.body,
                        customer_id: customer.id
                    }
                });
            }
        } else {
            apiRequest = request;
        }

        return next.handle(apiRequest);
    }
}
