import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { LocalStorageManagementService } from '../services/base/local-storage-management.service';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private localStorageService: LocalStorageManagementService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.localStorageService.getToken()) {
            this.router.navigateByUrl('/');
            return false;
        } else {
            return true;
        }
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }

}
