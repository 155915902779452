import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ImageCroppedEvent } from 'ngx-image-cropper';

interface ImageCropperDialogData {
    image: File;
}

@Component({
    selector: 'rc-image-cropper-dialog',
    templateUrl: './image-cropper-dialog.component.html',
    styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
    public croppedImage!: string | null | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ImageCropperDialogData
    ) { }

    ngOnInit(): void {
        //
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

}
