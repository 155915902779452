import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import jwtDecode, { JwtPayload } from 'jwt-decode';
import { fromUnixTime, isBefore } from 'date-fns';

import { LocalStorageManagementService } from './local-storage-management.service';
import { ThemeService } from '../theme.service';

import { AuthResponse } from '@app/shared/models/auth-response.model';
import { Customer } from '@app/shared/models/customer.model';
import { TokenResponse } from '@app/shared/models/token-response.model';
import { User } from '@app/shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private notifyUserLoginSource = new Subject<User>();
    public notifyUserLogin$ = this.notifyUserLoginSource.asObservable();

    constructor(
        private httpClient: HttpClient,
        private localStorageService: LocalStorageManagementService,
        private router: Router,
        private themeService: ThemeService
    ) { }

    login(email: string, password: string): Observable<AuthResponse> {
        const url = 'login';
        const payload = {
            email,
            password,
            remember_me: false
        };

        return this.httpClient.post<AuthResponse>(url, payload).pipe(
            tap((response: AuthResponse) => this.localStorageService.setAuthTokens(response))
        );
    }

    logout(): Observable<any> {
        const url = 'logout';

        return this.httpClient.post<any>(url, {}).pipe(
            finalize(() => {
                this.localStorageService.deleteTokens();
                this.localStorageService.deleteUserData();
                this.localStorageService.deleteSelectedCustomer();
                this.themeService.setDefaultAppTheme();
                this.router.navigateByUrl('/auth/login');
            })
        );
    }

    getLoggedUser(verbose: boolean = false): Observable<User> {
        const url = 'logged_user';
        const params = { verbose: verbose.toString() };
        const cachedUser = this.localStorageService.getUserData();
        const cachedCustomer = this.localStorageService.getSelectedCustomer();

        if (cachedUser && !verbose) {
            if (!cachedCustomer) {
                this.localStorageService.setSelectedCustomer(cachedUser.customers[0]);
            }
            return of(cachedUser);
        } else {
            return this.httpClient.get<User>(url, { params }).pipe(
                tap((response: User) => {
                    this.localStorageService.setUserData(response);
                    if (!cachedCustomer) {
                        this.localStorageService.setSelectedCustomer(response.customers[0]);
                    } else {
                        const selectedCustomer = this.localStorageService.getSelectedCustomer() ?? response.customers[0];
                        this.localStorageService.setSelectedCustomer(
                            response.customers.find((customer: Customer) => customer.id === selectedCustomer.id) as Customer
                        );
                    }
                })
            );
        }
    }

    signup(token: string, password: string): Observable<void> {
        const url = 'finalize_user_registration';
        const payload = {
            encrypted_user_token: token,
            password: password,
            password_confirmation: password
        };

        return this.httpClient.post<void>(url, payload);
    }

    refreshToken(): Observable<AuthResponse> {
        const params = {
            refresh_token: this.localStorageService.getRefreshToken()
        };

        return this.httpClient.post<AuthResponse>('token/refresh', params).pipe(
            tap(
                (response: AuthResponse) => {
                    this.localStorageService.setAuthTokens(response);
                    return true;
                },
                () => {
                    this.logout().subscribe();
                    return false;
                }
            )
        );
    }

    getTokenExpirationDate(token: string): Date | null {
        try {
            const decoded = jwtDecode<JwtPayload>(token);

            if (decoded.exp === undefined) {
                return null;
            }

            return fromUnixTime(decoded.exp);
        } catch (error) {
            return null;
        }
    }

    isTokenExpired(token?: string): boolean {
        const authToken = token || this.localStorageService.getToken();

        if (!authToken) {
            return true;
        }

        const date = this.getTokenExpirationDate(authToken);

        if (!date) {
            return true;
        }

        return isBefore(date, new Date());
    }

    //
    // ─── PASSWORD MANAGEMENT ────────────────────────────────────────────────────────
    //

    validateUserToken(token: string): Observable<TokenResponse> {
        const url = 'validate_user_token';
        const payload = {
            encrypted_user_token: token
        };

        return this.httpClient.post<TokenResponse>(url, payload);
    }

    requestPasswordReset(email: string): Observable<void> {
        const url = 'reset_user_password';
        const payload = {
            user_email: email
        };

        return this.httpClient.post<void>(url, payload);
    }

    changeUserPassword(newPassword: string, token?: string): Observable<void> {
        const url = 'change_user_password';
        const payload = {
            password: newPassword,
            password_confirmation: newPassword
        };
        let headers = new HttpHeaders();
        if (token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }

        return this.httpClient.post<void>(url, payload, { headers });
    }

    changeLoggedUserPassword(newPassword: string): Observable<void> {
        const url = 'change_logged_user_password';
        const payload = {
            password: newPassword,
            password_confirmation: newPassword
        };

        return this.httpClient.post<void>(url, payload);
    }

    //
    // ─── RXJS ───────────────────────────────────────────────────────────────────────
    //

    notifyUserLogin(user: User): void {
        this.notifyUserLoginSource.next(user);
    }

}
