import { Component, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';

import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';

@Component({
    selector: 'rc-logo-uploader',
    templateUrl: './logo-uploader.component.html',
    styleUrls: ['./logo-uploader.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LogoUploaderComponent),
        multi: true
    }]
})
export class LogoUploaderComponent implements OnInit, ControlValueAccessor {
    public uploaded = false;
    public fileName!: string;
    public fileData!: string;
    public files: UploadFile[] = [];
    public uploadInput!: EventEmitter<UploadInput>;
    public options!: UploaderOptions;
    public onChange = (data: string) => { };
    public onTouched = () => { };

    constructor(
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.setupUploader();
    }

    setupUploader(): void {
        this.options = { concurrency: 1, maxFileSize: 1000000 };
    }

    //
    // ─── UPLOADER METHODS ───────────────────────────────────────────────────────────
    //

    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            this.files.push(output.file);

            const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
                width: '600px',
                data: { image: output.file.nativeFile }
            });

            dialogRef.afterClosed().subscribe((result: string) => {
                this.writeValue(result);
                this.onChange(result);
            });
        }
    }

    //
    // ─── CONTROL VALUE ACCESSOR METHODS ─────────────────────────────────────────────
    //

    writeValue(data: string): void {
        this.fileData = data;
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

}
