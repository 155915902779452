import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { Constants } from '../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    public color500Brightness!: number;
    public color200Brightness!: number;

    public primaryColorHsl!: string;
    public brightPrimaryColorHsl!: string;

    private darkContrastColor = '#202122';
    private lightContrastColor = '#FFFFFF';
    private colorBrightnessThreshold = 98;

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    getThemePrimaryColor(): string {
        return getComputedStyle(this.document.documentElement)
            .getPropertyValue('--primary-color-500');
    }

    getThemeAccentColor(): string {
        return getComputedStyle(this.document.documentElement)
            .getPropertyValue('--primary-color-200');
    }

    setDefaultAppTheme(): void {
        const documentStyle = this.document.documentElement.style;

        documentStyle.setProperty('--primary-color-500', Constants.DEFAULT_PRIMARY_COLOR);
        documentStyle.setProperty('--primary-color-500-contrast', Constants.DEFAULT_PRIMARY_COLOR_CONTRAST);

        documentStyle.setProperty('--primary-color-200', Constants.DEFAULT_ACCENT_COLOR);
        documentStyle.setProperty('--primary-color-200-contrast', Constants.DEFAULT_ACCENT_COLOR_CONTRAST);
    }

    updateAppTheme(primaryColor: string, accentColor: string): void {
        const documentStyle = this.document.documentElement.style;

        documentStyle.setProperty('--primary-color-500', primaryColor);
        documentStyle.setProperty('--primary-color-500-contrast', this.getContrastColor(primaryColor));

        documentStyle.setProperty('--primary-color-200', accentColor);
        documentStyle.setProperty('--primary-color-200-contrast', this.getContrastColor(accentColor));
    }

    updateAppThemePreview(primaryColor: string, accentColor: string): void {
        const documentStyle = this.document.documentElement.style;

        documentStyle.setProperty('--primary-color-500-preview', primaryColor);
        documentStyle.setProperty('--primary-color-500-contrast-preview', this.getContrastColor(primaryColor));

        documentStyle.setProperty('--primary-color-200-preview', accentColor);
        documentStyle.setProperty('--primary-color-200-contrast-preview', this.getContrastColor(accentColor));
    }

    //
    // ─── UTILITY METHODS ────────────────────────────────────────────────────────────
    //

    getContrastColor(hslColor: string): string {
        const brightness = +(hslColor.substring(hslColor.lastIndexOf(',') + 1, hslColor.lastIndexOf('%')));
        return brightness <= this.colorBrightnessThreshold
            ? this.lightContrastColor
            : this.darkContrastColor;
    }

    getHslColorGroups(hslColor: string): number[] | null {
        const hslColorWithoutPercentage = hslColor.replace(/%/g, '');
        const regex = /^(rgb|hsl)(a?)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/gm;
        const match = regex.exec(hslColorWithoutPercentage);
        const hslColorArray: number[] | null = [];

        if (match) {
            hslColorArray.push(+match[3]);
            hslColorArray.push(+match[4]);
            hslColorArray.push(+match[5]);
        }

        return hslColorArray;
    }

    hslToHex(hslColorArray: number[] | null): string {
        let hexColor = '';

        if (hslColorArray) {
            const h = hslColorArray[0] / 360;
            const s = hslColorArray[1] / 100;
            const l = hslColorArray[2] / 100;

            let r; let g; let b;
            if (s === 0) {
                r = g = b = l;
            } else {
                const hue2rgb = (p: number, q: number, t: number) => {
                    if (t < 0) {
                        t += 1;
                    }
                    if (t > 1) {
                        t -= 1;
                    }
                    if (t < 1 / 6) {
                        return p + (q - p) * 6 * t;
                    }
                    if (t < 1 / 2) {
                        return q;
                    }
                    if (t < 2 / 3) {
                        return p + (q - p) * (2 / 3 - t) * 6;
                    }
                    return p;
                };
                const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
                const p = 2 * l - q;
                r = hue2rgb(p, q, h + 1 / 3);
                g = hue2rgb(p, q, h);
                b = hue2rgb(p, q, h - 1 / 3);
            }
            const toHex = (x: number) => {
                const hex = Math.round(x * 255).toString(16);
                return hex.length === 1 ? '0' + hex : hex;
            };

            hexColor = '#' + toHex(r) + toHex(g) + toHex(b);
        }

        return hexColor;
    }

    getHexColor(hslColor: string): string {
        const hslColorGroups = this.getHslColorGroups(hslColor);

        return this.hslToHex(hslColorGroups);
    }

    //
    // ─── LEGACY METHODS ─────────────────────────────────────────────────────────────
    //

    getColor(brightness: string, isPreviewElement: boolean, isContrastColor: boolean): string {
        const previewSuffix = isPreviewElement ? '-preview' : '';
        const contrastSuffix = isContrastColor ? '-contrast' : '';

        return getComputedStyle(document.documentElement)
            .getPropertyValue(`--primary-color-${brightness}${contrastSuffix}${previewSuffix}`);
    }

    setColor(color: string, brightness: string, isPreviewElement: boolean = false, isContrastColor: boolean = false): void {
        const previewSuffix = isPreviewElement ? '-preview' : '';
        const contrastSuffix = isContrastColor ? '-contrast' : '';
        this.document.documentElement.style
            .setProperty(`--primary-color-${brightness}${contrastSuffix}${previewSuffix}`, color);
    }

    getContrastColors(brightness: number): string {
        const colorBrightnessThreshold = 60;
        const contrastColor = brightness <= colorBrightnessThreshold
            ? this.lightContrastColor
            : this.darkContrastColor;

        return contrastColor;
    }

    getAccentColor(hslColor: string): string {
        const brightnessDifferenceValue = 25;
        this.color500Brightness = +(hslColor.substring(hslColor.lastIndexOf(',') + 1, hslColor.lastIndexOf('%')));

        if ((this.color500Brightness + brightnessDifferenceValue) > 100) {
            this.color200Brightness = 98;
        } else {
            this.color200Brightness = this.color500Brightness + brightnessDifferenceValue;
        }

        const splittedColor = hslColor.split(',');

        return `${splittedColor[0]}, ${splittedColor[1]}, ${this.color200Brightness}%)`;
    }

    setPrimaryColorLight(hslColor: string): void {
        const brightnessDifferenceValue = 25;
        this.color500Brightness = +(hslColor.substring(hslColor.lastIndexOf(',') + 1, hslColor.lastIndexOf('%')));

        if ((this.color500Brightness + brightnessDifferenceValue) > 100) {
            this.color200Brightness = 98;
        } else {
            this.color200Brightness = this.color500Brightness + brightnessDifferenceValue;
        }

        const splittedColor = hslColor.split(',');
        const newBrightColor = `${splittedColor[0]}, ${splittedColor[1]}, ${this.color200Brightness}%)`;

        this.brightPrimaryColorHsl = newBrightColor;
        this.setColor(newBrightColor, '200', true, false);
    }

    changeThemePreview(hslColor: string): void {
        this.setColor(hslColor, '500', true);
        this.primaryColorHsl = hslColor;
        this.setPrimaryColorLight(hslColor);

        //set primary-color-500-contrast
        this.setColor(this.getContrastColors(this.color500Brightness), '500', true, true);

        //set primary-color-200-contrast
        this.setColor(this.getContrastColors(this.color200Brightness), '200', true, true);
    }
}
