<h1 mat-dialog-title>{{'COMMON.CUSTOMIZE_LOGO' | translate}}</h1>

<div mat-dialog-content>
    <image-cropper format="png" [imageFile]="data.image" [maintainAspectRatio]="false"
        (imageCropped)="imageCropped($event)">
    </image-cropper>
</div>

<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'COMMON.CANCEL' | translate}}</button>
    <button mat-button [mat-dialog-close]="croppedImage" cdkFocusInitial>{{'COMMON.CONFIRM' | translate}}</button>
</div>
