import { Injectable, Injector } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { tap, finalize, catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/base/auth.service';
import { LocalStorageManagementService } from '../services/base/local-storage-management.service';

import { Constants } from '../constants/constants';
import { AuthResponse } from '@app/shared/models/auth-response.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authService: any;
    private refreshTokenInProgress = false;

    private tokenRefreshedSource = new Subject<AuthResponse>();
    private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(
        private injector: Injector,
        private localStorageService: LocalStorageManagementService,
        private router: Router
    ) { }

    addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
        if (this.localStorageService.getItem(Constants.TOKEN_NAME)) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.localStorageService.getItem(Constants.TOKEN_NAME)}`
                }
            });
        }

        return request;
    }

    refreshToken(): Observable<AuthResponse> {
        if (this.refreshTokenInProgress) {
            return new Observable((observer: any) => {
                this.tokenRefreshed$.subscribe((response: AuthResponse) => {
                    observer.next(response);
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.authService.refreshToken().pipe(
                tap((response: AuthResponse) => {
                    this.tokenRefreshedSource.next(response);
                }),
                finalize(() => (this.refreshTokenInProgress = false))
            );
        }
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.authService = this.injector.get(AuthService);
        this.localStorageService = this.injector.get(LocalStorageManagementService);

        if (!request.headers.get('Authorization')) {
            request = this.addAuthHeader(request);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (
                    error.status === 401 &&
                    !request.url.includes('login') &&
                    !request.url.includes('logout') &&
                    !request.url.includes('token/refresh')
                ) {
                    if (this.localStorageService.getRefreshToken()) {
                        return this.refreshToken().pipe(
                            tap((response: AuthResponse) => {
                                this.localStorageService.setToken(response.token);
                                this.localStorageService.setRefreshToken(response.refresh_token);
                            }),
                            catchError(() => {
                                this.localStorageService.clearLocalStorage();
                                this.router.navigateByUrl('/auth/login');
                                return EMPTY;
                            }),
                            switchMap(() => {
                                request = this.addAuthHeader(request);
                                return next.handle(request);
                            }),
                            finalize(() => {
                                this.refreshTokenInProgress = false;
                            })
                        );
                    } else {
                        this.localStorageService.clearLocalStorage();
                        this.router.navigateByUrl('/auth/login');
                        return EMPTY;
                    }
                }
                return throwError(error);
            })
        ) as Observable<HttpEvent<unknown>>;
    }
}
