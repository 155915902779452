import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Data } from '@angular/router';

import { User } from '@app/shared/models/user.model';
import { DataProtectionDialogComponent } from './data-protection-dialog/data-protection-dialog.component';

@Component({
    selector: 'rc-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public loggedUser!: User;

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.getRouteData();
    }

    getRouteData(): void {
        this.route.data.subscribe((data: Data) => {
            if (data.loggedUser) {
                this.loggedUser = data.loggedUser;
            }
        });
    }

    openDataProtectionDialog(): void {
        this.dialog.open(DataProtectionDialogComponent);
    }

}
