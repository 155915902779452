import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { AuthService } from '../services/base/auth.service';
import { LocalStorageManagementService } from '../services/base/local-storage-management.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private authService: AuthService,
        private localStorageService: LocalStorageManagementService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.authService.isTokenExpired()) {
            if (this.localStorageService.getRefreshToken()) {
                return this.authService.refreshToken().pipe(
                    switchMap(() => of(true)),
                    catchError(() => {
                        this.router.navigateByUrl('/auth/login');
                        return of(false);
                    })
                );
            } else {
                this.router.navigateByUrl('/auth/login');
                return of(false);
            }
        } else {
            return true;
        }
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }

}
