import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ColorPickerModule } from 'ngx-color-picker';
import { TranslateModule } from '@ngx-translate/core';
import { NgxUploaderModule } from 'ngx-uploader';
import { ImageCropperModule } from 'ngx-image-cropper';

import { MaterialModule } from '@app/material/material.module';

import { BackendTranslationPipe } from './pipes/backend-translation.pipe';
import { RelativeDatePipe } from './pipes/relative-date.pipe';

import { AuthorizeOnlyDirective } from './directives/authorize-only.directive';
import { AuthorizeExceptDirective } from './directives/authorize-except.directive';
import { ValidCountryDirective } from './validators/valid-country.directive';

import { LayoutPagesComponent } from './layouts/layout-pages/layout-pages.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LayoutAuthComponent } from './layouts/layout-auth/layout-auth.component';
import { UploadComponent } from './components/upload/upload.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DataProtectionDialogComponent } from './components/footer/data-protection-dialog/data-protection-dialog.component';
import { LogoUploaderComponent } from './components/logo-uploader/logo-uploader.component';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';

@NgModule({
    declarations: [
        AuthorizeOnlyDirective,
        AuthorizeExceptDirective,
        BackendTranslationPipe,
        LayoutPagesComponent,
        HeaderComponent,
        FooterComponent,
        LayoutAuthComponent,
        UploadComponent,
        ValidCountryDirective,
        ConfirmationDialogComponent,
        RelativeDatePipe,
        DataProtectionDialogComponent,
        LogoUploaderComponent,
        ImageCropperDialogComponent,
        ColorPickerComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        TranslateModule,
        NgxUploaderModule,
        ImageCropperModule,
        ColorPickerModule
    ],
    exports: [
        AuthorizeOnlyDirective,
        AuthorizeExceptDirective,
        TranslateModule,
        BackendTranslationPipe,
        RelativeDatePipe,
        FooterComponent,
        LogoUploaderComponent,
        ColorPickerComponent
    ]
})
export class SharedModule { }
